import React from 'react'

import routes from 'src/lib/rails-routes/rails-routes'
import PaymentForm from 'src/components/PaymentForm/PaymentForm'
import { Box } from '@chatterbug/aaron'
import { Modal } from '@chatterbug/aaron/modal'
import { post } from 'src/lib/util'
import { stripePublicKey } from 'src/config/globals'

export type AddPaymentSourceModalProps = {
  allowSEPA: boolean
  currency: string
  organization?: string
  isOpen: boolean
  onClose: () => void
}

const AddPaymentSourceModal: React.FC<AddPaymentSourceModalProps> = ({
  allowSEPA,
  currency,
  organization,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Box width={{ _: 'auto', tablet: '700px' }} textAlign="center" m={'4x'}>
        <PaymentForm
          paymentMethods={allowSEPA ? ['card', 'iban'] : ['card']}
          currency={currency}
          stripePublishedKey={stripePublicKey}
          onStripeResponse={async (paymentId: string) => {
            const { data } = await post(
              routes.payments_sources(),
              {
                token: paymentId,
                organization: organization,
              },
            )
            const redirectPath = data && data.redirect_to
            if (redirectPath) {
              window.location = redirectPath
            } else {
              window.location.reload()
            }
          }}
        />
      </Box>
    </Modal>
  )
}

export default AddPaymentSourceModal
